<template>
  <div class="dashboard-content">
    <div class="row">
      <div class="col-12 text-center">
        <h3>{{ $t("Views.AdminCompanyGalery.title") }} {{ company.name }}</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <div class="card" style="padding: 20px">
          <h4 style="margin-bottom: 20px">{{ $t("Views.AdminCompanyGalery.images") }}</h4>
          <v-row>
            <v-col
              v-for="image in company.images"
              :key="image.id"
              class="d-flex child-flex justify-content-center"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-img
                :src="image.route"
                :alt="`image`"
                max-width="200"
                :lazy-src="image.route"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-1" align="center" justify="center">
                    <v-progress-circular
                      class="mx-auto"
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <v-btn
                  color="#03184d"
                  font
                  fab
                  x-small
                  absolute
                  right
                  title="Eliminar archivo"
                  style="color: white; right: 0"
                  @click="deleteFile(image.id)"
                  ><i class="fa fa-remove"></i
                ></v-btn>
              </v-img>
               
            </v-col>
            <v-col
              class="d-flex child-flex"
              cols="12"
              v-if="company.images && company.images.length === 0"
            >
              <p>{{ $t("Views.AdminCompanyGalery.notLoadedImage") }}</p>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-sm-12 text-center">
        <div class="card" style="padding: 0px 5%; height: 100%">
          <h4 class="mt-4">{{ $t("Views.AdminCompanyGalery.logo") }}</h4>
          <v-col
            class="d-flex child-flex col-centered mx-auto"
            style=""
            cols="8"
            v-if="company.logo"
          >
            <v-img
              
              :src="company.logo.route"
              :lazy-src="``"
              :alt="'logo'"
              aspect-ratio="3"
              contain
              class="grey lighten-2"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-3" align="center" justify="center">
                  <v-progress-circular
                    class="mx-auto"
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
                 
              </template>
                         <v-btn
                  color="#03184d"
                  font
                  fab
                  x-small
                  absolute
                  right
                  title="Eliminar archivo"
                  style="color: white; right: 0"
                  @click="deleteFile(company.logo.id, 'logo')"
                  ><i class="fa fa-remove"></i
                ></v-btn>
            </v-img>
          </v-col>
          <v-col class="d-flex child-flex" cols="12" v-else>
            <p>{{ $t("Views.AdminCompanyGalery.notLoadedLogo") }}</p>
          </v-col>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="card add-listing-section">
          <h6 class="mx-auto">{{ $t("Views.AdminCompanyGalery.uploadLogo") }}</h6>
          <!-- Dropzone -->
          <dropzone
            ref="dropzoneLogo"
            id="dropzoneLogo"
            v-on:vdropzone-canceled="canceledLogoUpload"
            v-on:vdropzone-success="successLogoUpload"
            v-on:vdropzone-sending="sendingLogoEvent"
            v-on:vdropzone-total-upload-progress="uploadProgress"
            v-on:vdropzone-error="uploadError"
            :options="dropzoneLogoOptions"
            :useCustomSlot="true"
          >
            <div class="dropzone-custom-content">
              <div class="subtitle">
                {{ $t("Views.AdminCompanyGalery.dropzoneLogoText") }}
              </div>
            </div>
          </dropzone>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="card add-listing-section">
          <h6 class="mx-auto">{{ $t("Views.AdminCompanyGalery.uploadImage") }}</h6>
          <!-- Dropzone -->
          <dropzone
            ref="dropzoneImage"
            id="dropzoneImage"
            v-on:vdropzone-canceled="canceledImageUpload"
            v-on:vdropzone-success="successImageUpload"
            v-on:vdropzone-sending="sendingImageEvent"
            v-on:vdropzone-total-upload-progress="uploadProgress"
            v-on:vdropzone-error="uploadError"
            :options="dropzoneImageOptions"
            :useCustomSlot="true"
          >
            <div class="dropzone-custom-content">
              <div class="subtitle">
                {{ $t("Views.AdminCompanyGalery.dropzoneImageText") }}
              </div>
            </div>
          </dropzone>
        </div>
      </div>
    </div>

    <div class="row">
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            style="margin: auto; margin-top: 10px"
            color="#0c2c7c"
            dark
            @click="$router.go(-1)"
          >
            {{ $t("Views.AdminCompanyGalery.backButton") }}
          </v-btn>
        </div>
      </v-col>
    </div>

    <!-- Section / End -->
  </div>
</template>
<script>
import Dropzone from "vue2-dropzone";
export default {
  name: "CompanyGalery",
  components: {
    Dropzone,
  },
  data() {
    return {
     
      reloadingImages: false,
      company: {},
      error: "",
      pdfSource: "",
      dropzoneImageOptions: {
        url: process.env.VUE_APP_FIAF_API + "companies/" + this.$route.query.id,
        method: "POST",
        thumbnailWidth: 100,
        addRemoveLinks: true,

        acceptedFiles: ".jpg, .jpeg, .png, .gif",
        dictDefaultMessage:
          "<i class='fa fa-cloud-upload'></i>Arrastra y suelta, o haz clic para seleccionar un archivo de tu dispositivo",
        maxFilesize: 0.3,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("vue-session-key")).tokenSession,
        },
      },

      dropzoneLogoOptions: {
        url: process.env.VUE_APP_FIAF_API + "companies/" + this.$route.query.id,
        method: "POST",
        thumbnailWidth: 100,
        addRemoveLinks: true,
        acceptedFiles: ".svg, .jpg, .jpeg, .png",
        dictDefaultMessage:
          "<i class='fa fa-cloud-upload'></i>Arrastra y suelta, o haz clic para seleccionar un archivo de tu dispositivo",
        maxFilesize: 0.2,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("vue-session-key")).tokenSession,
        },
      },
    };
  },
  created() {
    this.getCompany();
  },
  methods: {
    async getCompany() {
      this.loading = true;
      axios.get("companies/" + this.$route.query.id, this.config).then((response) => {
        this.company = response.data;
        this.$refs.dropzoneImage.setOption(
          "url",
          process.env.VUE_APP_FIAF_API + "companies/" + this.company.id
        );
        Object.assign(this.company, {
          images: this.company.uploads.filter((file) => file.type == "image"),
        });
        this.loading = false;
      });
    },
    setUrlImage() {
      this.$refs.dropzoneImage.setOption(
        "url",
        process.env.VUE_APP_FIAF_API + "companies/" + this.company.id
      );
    },
    sendingImageEvent(file, xhr, formData) {
      formData.append("fileType", "image");
      formData.append("fileResize[width]", 600);
      formData.append("fileResize[height]", 350);
      formData.append("_method", "patch");
    },

    sendingLogoEvent(file, xhr, formData) {
      if (this.company.logo) {
        formData.append("fileId", this.company.logo.id);
      }
      formData.append("fileType", "logo");
      formData.append("_method", "patch");
    },
    uploadProgress(totaluploadprogress, totalBytes, totalBytesSent) {},
    canceledImageUpload(file) {
      this.$refs.dropzoneImage.removeFile(file);
      this.$emit("vdropzone-file-added", file);
    },

    canceledLogoUpload(file) {
      this.$refs.dropzonelogo.removeFile(file);
      this.$emit("vdropzone-file-added", file);
    },

    successLogoUpload(file, response) {
      this.getCompany();
      this.$refs.dropzoneLogo.removeFile(file);
    },

    successImageUpload(file, response) {
      this.getCompany();
      this.$refs.dropzoneImage.removeFile(file);
    },

    uploadError(file, message, xhr) {
      this.error = message;
      if (message.message) {
        this.$swal({
          icon: "error",
          title: `Error en la carga del archivo ${file.name}`,
          html: `Mensaje: ${message.message} <br /> Por favor, intente nuevamente.`,
          showCancelButton: false,
          showConfirmButton: true,
        });
      } else {
        this.$swal({
          icon: "error",
          title: `Error en la carga del archivo ${file.name}`,
          html: `Mensaje: ${message} <br /> Por favor, intente nuevamente.`,
          showCancelButton: false,
          showConfirmButton: true,
        });
      }
    },
    async deleteFile(id, fileType) {
   
    axios.delete("/upload-management/delete-upload/" + id).then(() => {
      
        this.$swal({
          icon: "success",
          title: `Eliminado`,
          showCancelButton: false,
          showConfirmButton: true,
        });
        
        this.getCompany();
        Object.assign(this.company, {
          images: this.company.uploads.filter((file) => file.id != id),
        });
      });
    },
  },
};
</script>
<style lang="scss">
.add-listing-section {
  height: 100%;
}
</style>
